import React, { useState } from 'react';
import backgroundImage from './assets/bglogin.png'; 
import { useNavigate } from 'react-router-dom';

import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      console.error('Error signing in with password and email', error);
    }
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="bg-tmbblack bg-opacity-60 p-8 rounded-lg w-full max-w-md">
        <h1 className="text-4xl text-white text-center mb-4">Welcome</h1>
        <p className="text-gray-300 text-center mb-8">
          Unlock the future of Mixing/Mastering with The Mix Button. Log in or Sign up below
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2" htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              className="w-full p-3 rounded bg-gray-800 text-white focus:outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-300 mb-2" htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              className="w-full p-3 rounded bg-gray-800 text-white focus:outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between mb-4">
            <a href="#signup" className="text-red-500 hover:underline">Sign Up</a>
          </div>
          <button
            type="submit"
            className="w-full p-3 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

